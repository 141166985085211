<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form
        @submit.stop.prevent="handleSubmit($parent.quotation(dataForm))"
        class="form"
        autocomplete="off"
      >
        <div v-if="!checkConventinalFlow" class="row">
          <div class="col-12 col-lg-12 mb-3">
            <label class="dbo-ui" for="">Tipo de Recurso</label>
            <validation-provider
              name="tipo de recurso"
              rules="required">
              <div slot-scope="ProviderProps">
                <select
                  class="form-control"
                  :class="{ 'is-invalid': ProviderProps.errors[0] }"
                  v-model="dataForm.resource_type"
                  @change="onChangeResourceType"
                >
                <option disabled :value="null">Escolha um Tipo...</option>
                  <option
                    v-for="resource in resources"
                    :key="resource.id"
                    :value="resource.id"
                  >
                    {{ resource.name }}
                  </option>
                </select>
                <p class="invalid-feedback-custom">
                  {{ ProviderProps.errors[0] }}
                </p>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div :class="classContractJudicial">
            <validation-provider
              name="valor do contrato"
              rules="required"
              v-slot="validationContext"
            >
              <label class="dbo-ui">Valor da garantia</label>
              <money
                v-bind="money"
                v-model="dataForm.guarantee_value"
                :state="$parent.getValidationState(validationContext)"
                @keypress.native="$parent.getValidationLess($event)"
                class="form-control"
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div v-if="!checkPercentageThirty" class="col-6 col-lg-6 mb-3">
            <label class="dbo-ui">Valor da garantia com os 30%</label>
            <money
              disabled
              v-bind="money"
              :value="value_garantee_tax"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-3">
            <validation-provider
              name="data início"
              rules="required"
              v-slot="validationContext"
            >
              <label class="dbo-ui">Quando deve iniciar?</label>
              <b-form-input
                v-model="dataForm.guarantee_start"
                :min="dateNow"
                type="date"
                :state="$parent.getValidationState(validationContext)"
                placeholder=""
              ></b-form-input>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="col-6 mb-3">
            <label class="dbo-ui" for="">Quantidade de anos</label>
            <validation-provider
              name="quantidade de anos"
              rules="required">
              <div slot-scope="ProviderProps">
                <select
                  class="form-control"
                  :class="{ 'is-invalid': ProviderProps.errors[0] }"
                  v-model="dataForm.quantity_years"
                >
                <option disabled :value="null">Selecione...</option>
                  <option
                    v-for="(quantity, index) in quatityYears"
                    :key="quantity"
                    :value="quantity"
                  >
                    {{ index }}
                  </option>
                </select>
                <p class="invalid-feedback-custom">
                  {{ ProviderProps.errors[0] }}
                </p>
              </div>
            </validation-provider>
          </div>

        </div>

        <div v-show="!checkConventinalFlow" class="row">
          <div class="col-12 col-lg-12 mb-3">
            <div class="resource resource-warning resource-text">
              <p class="text-center">Valores vigentes a partir de 1 de Agosto de 2024, referentes aos limites de Depósito Recursal previstos no artigo 899 da CLT.</p>

              <table class="w-100 mb-3">
                <tbody>
                  <tr>
                    <td>Recurso Ordinário:</td>
                    <td class="text-right">R$ 13.133,46</td>
                  </tr>
                  <tr>
                    <td>Recurso de Revista e Embargos:</td>
                    <td class="text-right">R$ 26.266,92</td>
                  </tr>
                  <tr>
                    <td>Recurso em Ação Rescisória:</td>
                    <td class="text-right">R$ 26.266,92</td>
                  </tr>
                  <tr>
                    <td>Recurso Extraordinário*</td>
                    <td class="text-right">R$ 26.266,92</td>
                  </tr>
                </tbody>
              </table>

              <small>*Embora o Ato preveja valor limite de depósito para interposição de Recurso Extraordinário, em decisão recente, o STF, apreciando o tema 679 de repercussão geral (RE 607.447), declarou inconstitucional a exigência de depósito prévio para interposição do Recurso Extraordinário em matéria trabalhista.</small>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-12 mt-5">
            <span
              @click="$parent.back()"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
              ><i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block">Voltar</span></span
            >
            <button
              :disabled="invalid || checkValues"
              class="btn btn-primary btn-lg text-uppercase px-4"
            >
              Preencha os dados do segurado <i class="fa fa-angle-right ml-2"></i>
            </button>
            <span v-b-tooltip.hover title="Necessário preencher campos obrigatórios para avançar." class="ml-2" v-if="invalid || checkValues">
              <i class="fas fa-exclamation-circle text-warning"></i>
            </span>
          </div>
        </div>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import translateResourceTypes from "@/enums/translate/resourceTypes";
import resourceTypes from "@/enums/resourceTypes";
import contractTypes from "@/enums/contractTypes";
import commonsService from "@/services/commons";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import dateFormatter from '@/mixins/dateFormatter';

export default {
  name: "GuaranteeData",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    dataJudicial: {
      type: Object
    },
    quotationCategory: {
      type: Object
    }
  },
  mixins: [
    dateFormatter,
  ],
  data: () => ({
    dataForm: { 
      contract_type: contractTypes.PUBLIC,
      resource_type: null,
      quantity_years: null, 
      guarantee_start: null 
    },
    translateResourceTypes: translateResourceTypes,
    resourceTypes: resourceTypes,
    insuranceCategoryTypes: insuranceCategoryTypes,
    checkInsuranceCategory: false,
    value_garantee_tax: 0,
    resources: [],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    dateNow: null
  }),
  computed: {
    checkValues() {
      return this.dataForm.guarantee_value == 0
    },
    allowedResources() {
      return [this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
              this.insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT]
    },
    disallowedPercentageThirty() {
      return  [
                this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT,
                this.insuranceCategoryTypes.TAX_ENFORCEMENT,
                this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION,
                this.insuranceCategoryTypes.CUSTOMS,
                this.insuranceCategoryTypes.CUSTOMS_TRANSIT,
                this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION,
                this.insuranceCategoryTypes.ENERGY_PAYMENT,
              ]
    },
    checkPercentageThirty(){
      if (!this.quotationCategory) {
         return false;
      }
      return this.disallowedPercentageThirty.indexOf(this.quotationCategory.id) > -1
    },
    classContractJudicial(){
      return !this.checkPercentageThirty ? 'col-6 mb-3' : 'col-12 mb-3'
    },
    valueGarantee() {
      return this.dataForm.guarantee_value
    },
    checkConventinalFlow(){
      if (!this.quotationCategory) {
         return false;
      }
      return this.allowedResources.indexOf(this.quotationCategory.id) == -1
    },
    quatityYears() {
      return {
        '3 anos (1096)': 3,
        '4 anos (1461)': 4,
        '5 anos (1826)': 5,
      }
    }
  },
  created(){
    this.findResource();
    this.setDataStart();
    this.dataForm.guarantee_start = this.formatDateISO(new Date());
  },
  methods: {
    fillFieldsJudicial(element) {
      if (element != "") {
        this.dataForm.quantity_years = element.quantity_years
        this.dataForm.resource_type = element.resource_type
        this.dataForm.guarantee_value = element.guarantee_value
        this.fillFieldsForRenewal(element.resource_type);
        this.$set(this.dataForm, 'guarantee_start', element.guarantee_start.substr(0, 10));
        this.checkGuaranteeStartDate();
      }
    },
    fillFieldsForRenewal(resource_type = null) {
        if(resource_type && this.$route.query.isRenewal) {
          this.findValueByResource(resource_type);
        }
    },
    findValueByResource(data) {
      var resource = this.resources.find(element => element.id == data)
      if(resource){
        this.dataForm.guarantee_value = resource.value;
      }
    },
    findResource() {
      commonsService
        .resourceTypes()
        .then((response) => {
          if (response.status == 200) {
            this.resources = response.data
          }
        })
        .catch(() => {
          this.$parent.showError("Não foi possível recuperar os recursos.");
        });
    },
    setDataStart() {
      if(this.dataForm.guarantee_start == null && (
        this.quotationCategory.id == insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT || 
        this.quotationCategory.id == insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT ||
        this.quotationCategory.id == insuranceCategoryTypes.ENSURE_A_LABOR_EXECUTION)) {
        this.dateNow = this.formatDateISO(new Date());
      }
    },
    isCategoryCurrentDate() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.PARTICIPATE_IN_A_BID_OR_COMPETITION,
      ].some(value => value === this.quotationCategory.id);
    },
    checkGuaranteeStartDate(){
      if(this.dataForm.guarantee_start < this.getCurrentDate() && this.isCategoryCurrentDate()){
        this.dataForm.guarantee_start = this.getCurrentDate();
      }
    },
    onChangeResourceType(event) {
        this.findValueByResource(event.target.value);
    }
  },
  watch:{
    dataJudicial(data) {
      this.fillFieldsJudicial(data)
    },
    valueGarantee(data) {
      this.value_garantee_tax = data + (data * 0.3)
    },
  }
};
</script>

<style>
</style>
