import http from "@/services/http";

const findCnpj = cnpj => http.get(`cnpj/${cnpj}`);
const states = () => http.get(`states`);
const statesUf = () => http.get(`states/uf`);
const resourceTypes = () => http.get(`resource-types`);
const insuranceTypes = () => http.get(`insurance-types`);
const getTerm = termId => http.get(`terms/${termId}`);
const insuranceCategories = insurance_type_id =>
  http.get(`insurance-types/${insurance_type_id}/insurance-categories`);
const cities = state_id =>
  http.get(`cities`, { params: { state_id: state_id } });
const findCEP = cep => http.get(`cep/${cep}/search`);
const insurers = (params = null) => http.get(`insurers`, { params: params });
const coverageWarrantie = () => http.get(`coverage-warranties`);
const appointmentLetters = () => http.get(`appointment-letters`);
const getAllInsurers = () => http.get(`insurers/list`);
const getAllInsurersWithIntegration = () => http.get(`insurers/list-with-integration`);
const findIntegration = (id, params = null) => http.get(`insurers/${id}`, { params: params })
const storeIntegrationData = (id, data) => http.put(`insurers-store/${id}`, data)
const appointmentLetterInsurers = () => http.get(`appointment-letters/insurers`);
const findCEPSimple = cep => http.get(`cep/${cep}`);

export default {
  findCnpj,
  states,
  statesUf,
  cities,
  findCEP,
  resourceTypes,
  insuranceTypes,
  insuranceCategories,
  insurers,
  coverageWarrantie,
  getTerm,
  appointmentLetters,
  getAllInsurers,
  appointmentLetterInsurers,
  findCEPSimple,
  findIntegration,
  storeIntegrationData,
  getAllInsurersWithIntegration
};
